import {
  DeleteWithConfirmButton,
  FormTab,
  required,
  SaveButton,
  TextInput,
  Toolbar,
  useGetList,
  useRecordContext,
  AutocompleteInputProps,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import {
  Flex,
  ReachTextEditor,
  ResourceAutocompleteArrayInput,
  ResourceAutocompleteInput,
  ResourceInputNumber,
  ResourceMoneyInput,
  ResourceTab,
  ResourceTabbedForm,
  RowInfoBlock,
} from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import { useGroupedCategoryList } from '@Hooks/useGroupedCategoryList';
import {
  accessPermission,
  isDiscountPriceSatisfactory,
  isEmptyAfterTrimmed,
  isInteger,
  maxLengthExt,
  minNum,
  pointsToUnits,
} from '@Helpers';

import { ResourceRoutes } from '../../../resourceRoutes';
import { STATUS_CHOICES } from '../share/constants';
import { ImageGallery } from '../share/Tabs/ImageGallary/ImageGallery';

import { ProductOffers } from './ProductOffers';
import { DEFAULT_PRODUCT_FORM_VALUES } from './constants';
import {
  GroupLabelStyled,
  GroupChildrenStyled,
  GroupedItemStyled,
} from './styled';

interface GetResourceAutocompleteInputProps {
  commodityGroupList: SelectOption[];
}

const validateName = [required(), isEmptyAfterTrimmed(), maxLengthExt(255)];
const validatePrice = [required(), minNum(parseFloat(pointsToUnits(1)))];
const validateSorting = [required(), isInteger()];
const validateDiscountPrice = [
  minNum(parseFloat(pointsToUnits(1))),
  isDiscountPriceSatisfactory(),
];

const GetResourceAutocompleteInput = ({
  commodityGroupList = [],
}: GetResourceAutocompleteInputProps) => {
  const { setValue } = useFormContext();
  const sourceName = 'commodityGroupId';

  const onChange = (val = '') => {
    const selectedOption = commodityGroupList.find(({ id }) => id === val);

    setValue('commodityGroup', selectedOption);
  };

  return (
    <ResourceAutocompleteInput
      name={sourceName}
      source={sourceName}
      choices={commodityGroupList}
      validate={required()}
      onChange={onChange}
      fullWidth
    />
  );
};

export const ProductForm = () => {
  const record = useRecordContext<CoreProduct>();
  const isMobile = useIsMobile();
  const nestedCategories = useGroupedCategoryList();

  const offerList = record?.offers || [];
  const { data: commodityGroupList = [] } = useGetList(
    ResourceRoutes.commodityGroup.resourcePath
  );

  return (
    <ResourceTabbedForm
      record={record}
      defaultValues={DEFAULT_PRODUCT_FORM_VALUES}
      toolbar={
        accessPermission.checkPermission('Catalogue', 'UD') ? (
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Flex gap={2}>
              <SaveButton />
            </Flex>

            <DeleteWithConfirmButton />
          </Toolbar>
        ) : (
          <></>
        )
      }
    >
      <FormTab label="catalogue.pages.products.tabs.general">
        <Flex asColumn={isMobile} gap={1} fullWidth>
          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              fullWidth
              name="name"
              source="name"
              validate={validateName}
            />

            <RowInfoBlock
              titleKey="catalogue.pages.products.labels.description"
              fullWidth
              sx={{ paddingInline: 0 }}
            >
              <ReachTextEditor name="description" />
            </RowInfoBlock>

            <ResourceMoneyInput
              name="price"
              source="price"
              label="catalogue.pages.products.labels.price"
              validate={validatePrice}
              fullWidth
            />

            <ResourceAutocompleteInput
              name="categoryId"
              source="categoryId"
              choices={nestedCategories}
              translateChoice={false}
              fullWidth
              validate={required()}
              groupBy={(item) => item.groupName}
              renderGroup={defaultRenderGroup}
            />

            <ResourceInputNumber
              name="sorting"
              source="sorting"
              label="catalogue.pages.products.labels.sorting"
              isInteger
              validate={validateSorting}
              fullWidth
            />
          </RowInfoBlock>

          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <ResourceAutocompleteInput
              name="status"
              source="status"
              optionText="name"
              optionValue="id"
              choices={STATUS_CHOICES}
              translateChoice
              fullWidth
              validate={required()}
            />

            <ResourceMoneyInput
              name="discountPrice"
              source="discountPrice"
              label="catalogue.pages.products.labels.discountPrice"
              validate={validateDiscountPrice}
              fullWidth
            />

            <ResourceAutocompleteArrayInput
              name="additionalCategoryIds"
              source="additionalCategoryIds"
              choices={nestedCategories}
              groupBy={(item) => item.groupName}
              renderGroup={defaultRenderGroup}
              fullWidth
            />

            <GetResourceAutocompleteInput
              commodityGroupList={commodityGroupList}
            />
          </RowInfoBlock>
        </Flex>
      </FormTab>

      <ResourceTab
        subscribedFields={['metadataTitle', 'metadataDescription']}
        label="catalogue.pages.products.tabs.seo"
      >
        <Flex asColumn fullWidth>
          <RowInfoBlock flex={1}>
            <TextInput
              fullWidth
              name="metadataTitle"
              source="metadataTitle"
              label="catalogue.pages.products.labels.metadataTitle"
            />
            <TextInput
              fullWidth
              name="metadataDescription"
              source="metadataDescription"
              multiline
              rows={5}
              label="catalogue.pages.products.labels.metadataDescription"
            />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <ResourceTab
        label="catalogue.pages.products.tabs.additionalLanguages"
        subscribedFields={['description']}
      >
        <Flex asColumn fullWidth>
          <RowInfoBlock
            flex={1}
            titleKey="catalogue.pages.products.labels.nameAr"
          >
            <TextInput
              fullWidth
              name="nameAr"
              source="nameAr"
              validate={maxLengthExt(255)}
              label="catalogue.pages.products.labels.nameAr"
            />
          </RowInfoBlock>
          <RowInfoBlock
            flex={1}
            titleKey="catalogue.pages.products.labels.descriptionAr"
          >
            <ReachTextEditor name="descriptionAr" isRequired={false} />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <ResourceTab
        label="catalogue.pages.products.tabs.images"
        subscribedFields={['previewImage']}
      >
        <ImageGallery
          type="products"
          mainImageSource="previewImage"
          imagesSource="images"
          isRequired
        />
      </ResourceTab>

      {/* TODO: uncomment when its needed */}
      {/* <ResourceTab */}
      {/*   label="catalogue.pages.products.tabs.additional" */}
      {/*   subscribedFields={['offerProperties']} */}
      {/* > */}
      {/*   <AdditionalProperties source="offerProperties" /> */}
      {/* </ResourceTab> */}

      {offerList.length > 0 && (
        <FormTab label="catalogue.pages.products.tabs.linkedOffers">
          <ProductOffers />
        </FormTab>
      )}
    </ResourceTabbedForm>
  );
};

const defaultRenderGroup: AutocompleteInputProps['renderGroup'] = ({
  key,
  group,
  children,
}) => (
  <GroupedItemStyled key={key}>
    {/* eslint-disable-next-line */}
    {/* @ts-ignore */}
    <GroupLabelStyled component="div">{group}</GroupLabelStyled>
    <GroupChildrenStyled>{children}</GroupChildrenStyled>
  </GroupedItemStyled>
);
