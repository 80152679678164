import { useEffect, useState, useRef } from 'react';
import {
  useTranslate,
  useRecordContext,
  Link,
  useShowContext,
} from 'react-admin';
import { Typography, Alert } from '@mui/material';

import { RowInfoBlock, Flex } from '@UI';
import { MoneyFormatter, DateFormatter, pointsToUnits } from '@Helpers';

import { loadPromocode } from '../../../utils';
import { UserInfo } from '../../components/UserInfo/UserInfo';

export const General = () => {
  const translate = useTranslate();
  const record = useRecordContext<CoreOrder>();
  const currencySymbol = MoneyFormatter.getCurrencySymbol(record.currency);
  const { isFetching } = useShowContext();

  const [promocodeId, setPromocodeId] = useState<number | undefined>();
  const timeoutRef = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

  useEffect(() => {
    const { promocode } = record;

    if (!promocode) {
      return;
    }

    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };

    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      loadPromocode(promocode)
        .then((response) => {
          if (!response) {
            return;
          }

          setPromocodeId(Number(response.id));
          timeoutRef.current = null;
        })
        .catch(console.warn);

      return () => {
        resetTimeout();
      };
    }, 500);
  }, [record]);

  const isOrderCancelled = record.status === 'cancelled';
  const isCancelledManually = record.cancel_reason === 'MANUALLY_CANCEL';
  const userCancellationMessage = record.feed?.find(
    (item) => item.type === 'STATUS' && item.new_value === 'cancelled'
  )?.user_message;

  return (
    <>
      {isOrderCancelled && (
        <Alert severity="error" variant="filled" sx={{ my: 2 }}>
          {record.cancel_reason ? (
            <>
              <Typography variant="body1">
                {translate('orders.pages.labels.cancelReason')}
              </Typography>
              <Typography variant="subtitle2">
                {translate(
                  `orders.pages.errors.cancelReasons.${record.cancel_reason}`
                )}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">
              {translate('orders.pages.labels.cancelledWithoutReason')}
            </Typography>
          )}
          {isCancelledManually && isFetching && (
            <Typography variant="subtitle1">
              {translate('orders.pages.labels.cancelReasonLoading')}
            </Typography>
          )}
          {isCancelledManually && !isFetching && (
            <Typography variant="subtitle1">
              {userCancellationMessage ||
                translate('orders.pages.labels.cancelReasonAbsent')}
            </Typography>
          )}
        </Alert>
      )}
      <RowInfoBlock titleKey="orders.pages.labels.sla.caption">
        <Flex asColumn>
          <Typography>
            {translate('orders.pages.labels.sla.startBy', {
              date: new DateFormatter(record.sla.start_after).relativeNow(),
            })}
          </Typography>

          <Typography>
            {translate('orders.pages.labels.sla.pickBy', {
              date: new DateFormatter(record.sla.ship_by).relativeNow(),
            })}
          </Typography>

          <Typography>
            {translate('orders.pages.labels.sla.deliverBy', {
              date: `${new DateFormatter(
                record.sla.deliver_by_min
              ).relativeNow()} - ${new DateFormatter(
                record.sla.deliver_by_max
              ).relativeNow()}`,
            })}
          </Typography>
        </Flex>
      </RowInfoBlock>

      <RowInfoBlock titleKey="orders.pages.labels.buyerInfo">
        <UserInfo type="namePhone" />
      </RowInfoBlock>

      <RowInfoBlock titleKey="orders.pages.labels.age.caption">
        <Typography>
          {translate(
            `orders.pages.labels.age.${
              record.customer?.[0].age_restriction
                ? 'confirmed'
                : 'notConfirmed'
            }`
          )}
        </Typography>
      </RowInfoBlock>

      <RowInfoBlock titleKey="orders.pages.labels.orderInfo.caption">
        <Typography>
          {translate('orders.pages.labels.orderInfo.itemsCount', {
            count: record.items.length,
          })}
        </Typography>

        <Typography>
          {translate('orders.pages.labels.orderInfo.deliveryCost', {
            amount:
              currencySymbol + pointsToUnits(record.delivery_price, false),
          })}
        </Typography>

        <Typography>
          {translate('orders.pages.labels.orderInfo.pricePaid', {
            amount:
              currencySymbol + pointsToUnits(record.actual_paid_price, false),
          })}
        </Typography>

        <Typography>
          {translate('orders.pages.labels.orderInfo.promocodeDiscount', {
            amount:
              currencySymbol +
              pointsToUnits(record.promocode_discount || 0, false),
          })}
        </Typography>

        {record.promocode && (
          <Typography>
            {translate('orders.pages.labels.orderInfo.promocodeValue')}{' '}
            {promocodeId ? (
              <Link to={`/promocodes/${promocodeId}`}>{record.promocode}</Link>
            ) : (
              record.promocode
            )}
          </Typography>
        )}

        <Typography>
          {translate('orders.pages.labels.orderInfo.pointsDiscount', {
            amount: currencySymbol + pointsToUnits(record.paid_bonuses, false),
          })}
        </Typography>

        <Typography>
          {translate('orders.pages.labels.orderInfo.smallOrderFee', {
            amount:
              currencySymbol + pointsToUnits(record.service_fee || 0, false),
          })}
        </Typography>

        <Typography>
          {translate('orders.pages.labels.orderInfo.paymentMethodCaption')}

          {translate(
            `orders.pages.labels.orderInfo.paymentMethod.${
              record.payment_method || 'CARD'
            }`
          )}
        </Typography>

        <Typography>
          {translate('orders.pages.labels.orderInfo.clientSource', {
            source: record.client_source,
          })}
        </Typography>

        {record.address?.notes && (
          <Typography sx={{ wordBreak: 'break-all' }}>
            {translate('orders.pages.labels.orderInfo.notes', {
              source: record.address.notes,
            })}
          </Typography>
        )}
      </RowInfoBlock>
    </>
  );
};
