import {
  GetListResult,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { AxiosError } from 'axios';
import add from 'date-fns/add';

import { RequestAPI } from '@RestApi';
import {
  generatePromocodeFromValues,
  generatePromocodeInput,
} from '@Plugins/Promotions/resources/Promocodes/utils';
import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { errorHandler } from '@Helpers/ErrorHandler';

import { MenuIcon } from './components/MenuIcon';
import { AffiliateResult } from './interfaces';
import { AFFILIATE_DEFAULT_VALUES } from './constants';
import { ListPage } from './Pages/ListPage';
import { AffiliateEditPage } from './Pages/AffiliateEditPage';

export const PROMOCODE_API = '/promocodes-service/v1/promocodes';
export const API_URL = `${PROMOCODE_API}/affiliate`;

const tapfilliateController = new PluginResourceController({
  menuItem: {
    icon: <MenuIcon />,
    caption: {
      translationKey: 'promotions.pages.tapfilliate.caption',
    },
    route: ResourceRoutes.promotions.tapfilliate.routePath,
  },
  resourceRoute: {
    name: ResourceRoutes.promotions.tapfilliate.resourcePath,
    list: <ListPage />,
  },
  customRoutes: [
    {
      path: `${ResourceRoutes.promotions.tapfilliate.resourcePath}/affiliate/:id`,
      element: <AffiliateEditPage />,
    },
  ],
  dataProvider: {
    getList: async (): Promise<GetListResult> => {
      let responseList: CoreAffiliate[] = [];

      try {
        const response: unknown = await RequestAPI.get(`${API_URL}/list`);

        responseList = (response as CoreAffiliate[]) || [];
      } catch (e) {
        if (e instanceof AxiosError) {
          errorHandler(e);
        }
      }

      return {
        data: responseList,
        total: responseList.length,
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
        },
      };
    },
    getOne: async (): Promise<GetOneResult> => {
      let instance: ReturnType<typeof generatePromocodeFromValues> = null;

      try {
        const response: unknown = await RequestAPI.get(`${API_URL}/template`, {
          headers: {
            Accept: 'application/json',
          },
        });

        // Sometimes we can receive text/plain response, so just checking for existence
        if (response !== '') {
          instance = generatePromocodeFromValues(response as CorePromocode);
        }
      } catch (e) {
        if (e instanceof AxiosError && e.status !== 404) {
          errorHandler(e);
        }
      }

      return {
        data: {
          id: 'unknown',
          exists: !!instance,
          instance:
            instance ||
            generatePromocodeFromValues({
              ...(AFFILIATE_DEFAULT_VALUES as unknown as CorePromocode),
              dateRange: [
                new Date().toISOString(),
                add(new Date(), { days: 4 }).toISOString(),
              ],
            }),
        },
      };
    },
    update: async (
      resource,
      params: UpdateParams<AffiliateResult>
    ): Promise<UpdateResult> => {
      const { data, previousData } = params;

      if (!data.instance) {
        return {
          data: previousData,
        };
      }

      if (!data.exists) {
        try {
          const requestData = generatePromocodeInput(data.instance);

          delete requestData.id;

          const response: unknown = await RequestAPI.post(`${API_URL}/template`, requestData);

          return {
            data: {
              id: 'unknown',
              exists: true,
              instance: generatePromocodeFromValues(response as CorePromocode),
            },
          };
        } catch (e) {
          errorHandler(e);
        }

        return {
          data: previousData,
        };
      }

      try {
        await RequestAPI.patch(
          `${API_URL}/template`,
          generatePromocodeInput(data.instance)
        );

        return {
          data: {
            id: 'unknown',
            exists: true,
            instance: data.instance,
          },
        };
      } catch (e) {
        errorHandler(e);
      }

      return {
        data: previousData,
      };
    },
  },
});

export default tapfilliateController;
