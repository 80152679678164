import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetListResult,
  GetOneParams,
  PaginationPayload,
  RaRecord,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import { G } from '@mobily/ts-belt';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { RequestAPI } from '@RestApi';
import { RequestAPIMethods } from '@ROOT/providers/dataProvider/rest/interface';
import { errorHandler } from '@Helpers/ErrorHandler';

import { ValidationError } from './ValidationError';

export interface ICommonCrud {
  list: (
    params: GetListParams,
    headers?: Record<string, string>
  ) => Promise<GetListResult>;

  getOne: <T>(
    params: GetOneParams,
    headers?: Record<string, string>
  ) => Promise<Nullable<T>>;

  create: <T>(
    params: CreateParams,
    headers?: Record<string, string>
  ) => Promise<Nullable<T>>;

  update: (
    params: UpdateParams,
    headers?: Record<string, string>
  ) => Promise<boolean>;

  uploadFile: (
    params: UpdateManyParams,
    headers?: Record<string, string>
  ) => Promise<boolean>;

  delete: (
    params: DeleteParams,
    headers?: Record<string, string>
  ) => Promise<boolean>;

  deleteMany: (
    params: DeleteManyParams,
    headers?: Record<string, string>
  ) => Promise<boolean>;
}

export interface CommonCrudOptions {
  isNewSorting?: boolean;
  errorHandler?: (error: Error) => void;
  arrayFormatter?: (key: string, values: string[]) => string;
  additionalHeaders?: Record<string, string>;
}

interface GetArbitraryListResult<RecordType extends RaRecord = RaRecord> {
  data: RecordType[];
  rawData: Record<string, unknown>;
  total: number;
}

export class CommonCrud implements ICommonCrud {
  isNewSorting?: boolean = false;
  protected apiUrl: string;
  protected currentFiltersQuery = '';
  protected additionalHeaders: Record<string, string> = {};
  protected errorHandler?: (error: Error) => void;
  protected options?: CommonCrudOptions;

  constructor(apiUrl: string, options?: CommonCrudOptions) {
    this.apiUrl = apiUrl;
    this.additionalHeaders = options?.additionalHeaders || {};
    this.errorHandler = options?.errorHandler;
    this.isNewSorting = options?.isNewSorting;
    this.options = options;
  }

  async list<RecordType extends RaRecord = RaRecord>(
    params: GetListParams,
    headers?: Record<string, string>
  ): Promise<GetListResult<RecordType>> {
    try {
      headers = { ...headers, ...this.additionalHeaders };
      const { sort, filter, pagination } = params;

      this.currentFiltersQuery = '';

      this._addToQuery(this.convertToGetQuery(filter));

      this._addSort(sort);
      this._addPagination(pagination);

      const response = await RequestAPI.get(
        `${this.apiUrl}${this.currentFiltersQuery}`,
        {
          headers,
        }
      );

      return {
        total: response.pagination?.total,
        data: response.data,
      };
    } catch (e) {
      try {
        this.handleError(e as Error);
      } catch (error) {
        if (
          error instanceof ValidationError ||
          (e as AxiosError).response?.status === 500
        ) {
          return {
            data: [
              // TODO: Fix typings
              // @ts-ignore
              { ...(error as Error), id: 0, message: (error as Error).message },
            ],
            total: 0,
          };
        }

        throw error;
      }

      return {
        data: [],
        total: 1,
      };
    }
  }

  async getArbitraryList<RecordType extends RaRecord = RaRecord>(
    params: { subPath: string; pagination: PaginationPayload },
    headers?: Record<string, string>,
    requestMethod: keyof RequestAPIMethods = 'get'
  ): Promise<GetArbitraryListResult<RecordType>> {
    try {
      headers = { ...headers, ...this.additionalHeaders };
      const { subPath, pagination } = params;

      this.currentFiltersQuery = '';
      this._addPagination(pagination);

      const response = await RequestAPI[requestMethod](
        `${this.apiUrl}${subPath}${this.currentFiltersQuery}`,
        {
          headers,
        }
      );

      const respData = response.data ?? response;
      const data = G.isNullable(respData)
        ? []
        : Array.isArray(respData)
        ? respData
        : [respData];

      return {
        data,
        rawData: response,
        total: response.total ?? data.length,
      };
    } catch (e) {
      try {
        this.handleError(e as Error);
      } catch (error) {
        if (
          error instanceof ValidationError ||
          (e as AxiosError).response?.status === 500
        ) {
          return {
            data: [
              // TODO: Fix typings
              // @ts-ignore
              { ...(error as Error), id: 0, message: (error as Error).message },
            ],
            total: 0,
          };
        }

        throw error;
      }

      return {
        data: [],
        rawData: {},
        total: 0,
      };
    }
  }

  async getOne<T>(
    params: GetOneParams,
    headers?: Record<string, string>
  ): Promise<Nullable<T>> {
    headers = { ...headers, ...this.additionalHeaders };

    try {
      return await RequestAPI.get(`${this.apiUrl}/${params.id}`, {
        headers,
      });
    } catch (e) {
      this.handleError(e as Error);

      return null;
    }
  }

  async create<T>(
    params: CreateParams,
    headers?: Record<string, string>
  ): Promise<Nullable<T>> {
    headers = { ...headers, ...this.additionalHeaders };

    try {
      return await RequestAPI.post(this.apiUrl, params.data, {
        headers,
      });
    } catch (e) {
      this.handleError(e as Error);

      return null;
    }
  }

  async post<T>(
    path: string,
    body?: Record<any, any>,
    headers?: Record<string, string>
  ): Promise<Nullable<T>> {
    headers = { ...headers, ...this.additionalHeaders };

    try {
      return await RequestAPI.post(`${this.apiUrl}/${path}`, body, {
        headers,
      });
    } catch (e) {
      this.handleError(e as Error);

      return null;
    }
  }

  async update(
    params: Omit<UpdateParams, 'previousData'>,
    headers?: AxiosRequestConfig['headers']
  ): Promise<boolean> {
    headers = { ...headers, ...this.additionalHeaders };

    try {
      const response = await RequestAPI.patch(
        `${this.apiUrl}/${params.id ?? params.data.id}`,
        {
          ...params.data,
          id: undefined,
        },
        {
          headers,
        }
      );

      return !!response;
    } catch (e) {
      this.handleError(e as Error);

      return false;
    }
  }

  async uploadFile(
    params: UpdateManyParams,
    headers?: Record<string, string>
  ): Promise<boolean> {
    headers = {
      ...headers,
      ...this.additionalHeaders,
      'Content-Type': 'multipart/form-data',
    };

    try {
      const formData = new FormData();

      formData.append('file', params.data);

      const response = await RequestAPI.post(
        `${this.apiUrl}/import`,
        formData,
        {
          headers,
        }
      );

      return !!response;
    } catch (e) {
      this.handleError(e as Error);

      return false;
    }
  }

  async delete(
    params: DeleteParams,
    headers?: Record<string, string>
  ): Promise<boolean> {
    headers = { ...headers, ...this.additionalHeaders };

    try {
      const response = await RequestAPI.delete(`${this.apiUrl}/${params.id}`, {
        headers,
      });

      return !!response;
    } catch (e) {
      this.handleError(e as Error);

      return false;
    }
  }

  async deleteMany(
    params: DeleteManyParams,
    headers?: Record<string, string>
  ): Promise<boolean> {
    headers = { ...headers, ...this.additionalHeaders };

    try {
      if (params.ids.length === 1) {
        await RequestAPI.delete(`${this.apiUrl}/${params.ids[0]}`, {
          headers,
        });
      } else {
        await RequestAPI.put(
          `${this.apiUrl}/delete`,
          {
            ids: params.ids,
          },
          {
            headers,
          }
        );
      }

      return true;
    } catch (e) {
      this.handleError(e as Error);

      return false;
    }
  }

  protected handleError(e: Error) {
    if (this.errorHandler) {
      this.errorHandler(e);

      return;
    }

    errorHandler(e);
  }

  protected _addPagination(pagination: GetListParams['pagination']) {
    const paginationGroup = 'page';
    const { perPage, page } = pagination;

    this._addSingleParamToQuery(`${paginationGroup}[size]`, perPage);
    this._addSingleParamToQuery(`${paginationGroup}[current]`, page);
  }

  protected _addSort(sort: GetListParams['sort']) {
    const sortGroup = 'sort';
    const { field, order } = sort;
    const newQuery = this.isNewSorting
      ? `${sortGroup}[0][field]=${field}&${sortGroup}[0][order]=${order}`
      : `${sortGroup}[${field}]=${order}`;

    this._addToQuery(newQuery);
  }

  protected convertToGetQuery(props?: Record<string, unknown>): string {
    return Object.entries(props || {})
      .filter(([, val]) => val !== undefined)
      .reduce((queryFragments: string[], [key, val]) => {
        const str =
          Array.isArray(val) && this.options?.arrayFormatter
            ? this.options?.arrayFormatter(key, val)
            : `${key}=${val}`;

        return queryFragments.concat(str);
      }, [])
      .join('&');
  }

  protected _addToQuery(attr: string) {
    if (!attr) {
      return;
    }

    const preparedCurrentFiltersQuery = this.prepareAttrStrToQuery(
      this.currentFiltersQuery
    );
    const preparedAttr = this.prepareAttrStrToQuery(attr);
    const url = new URLSearchParams(
      preparedCurrentFiltersQuery.concat(preparedAttr)
    );

    this.currentFiltersQuery = `?${decodeURI(url.toString())}`;
  }

  protected _addSingleParamToQuery(
    paramName: string,
    paramVal: string | number
  ) {
    const url = new URLSearchParams(this.currentFiltersQuery);

    url.set(paramName, `${paramVal}`);
    this.currentFiltersQuery = `?${url.toString()}`;
  }

  private prepareAttrStrToQuery(attrStr: string): string[][] {
    if (!attrStr) {
      return [];
    }

    const cleanedAttrStr = attrStr.startsWith('?')
      ? attrStr.substring(1)
      : attrStr;
    const params = cleanedAttrStr.split('&');

    return params.map((param) => param.split('='));
  }
}
