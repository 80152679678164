import { G } from '@mobily/ts-belt';
import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import {
  CreateResult,
  DeleteResult,
  GetListResult,
  GetManyReferenceResult,
  GetOneResult,
  HttpError,
  UpdateManyResult,
  UpdateResult,
} from 'react-admin';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { sortByOrderIndex } from '@Plugins/Catalogue/resources/share/helpers/sortAdditionalProperties';
import { OfferForm } from './OfferForm';
import {
  accessPermission,
  MoneyFormatter,
  pointsToUnits,
  unitsToPoints,
} from '@Helpers';
import { ListPage } from './ListPage';
import uploadImages from '../share/helpers/uploadImages';
import {
  submitPropertyValueByType,
  normalizeProperties,
} from '../share/helpers/normalizeProperties';
import { Icon } from '@UI';
import {
  inStockInformationApiUrl,
  offersApiUrl,
  ResourceMeta,
} from './constants';
import { InStockInformationCRUD } from './restApi/inStockInformationCRUD';
import { UPDATE_LIST_BY_FILE_UPLOAD } from '@ROOT/constants';
import { OfferCrud } from './restApi/OfferCrud';
import { BackendCodeError } from '../../../../base/AbstractApi/BackendCodeError';
import { sanitizePayload } from '../share/helpers/sanitizePayload';
import { ValidationError } from '@PluginManager/base/AbstractApi/ValidationError';
import pluginManager from '@PluginManager/PluginManager';

export const offerCrud = new OfferCrud(offersApiUrl, { isNewSorting: true });
const inStockInformationCrud = new InStockInformationCRUD(
  inStockInformationApiUrl,
  { isNewSorting: true }
);

const OffersController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'catalogue.pages.offers.caption',
    },
    route: ResourceRoutes.offer.routePath,
    icon: <Icon type="offer" />,
  },
  resourceRoute: {
    name: ResourceRoutes.offer.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <OfferForm />
      </ResourceItemPage>
    ),
  },

  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      const result = await offerCrud.list(params);

      result.data = (result.data || []).map((offer) => {
        if (offer.price !== undefined && offer.price !== null) {
          offer.price =
            MoneyFormatter.getCurrencySymbol() + pointsToUnits(offer.price);
        }

        if (offer.discountPrice !== undefined && offer.discountPrice !== null) {
          offer.discountPrice =
            MoneyFormatter.getCurrencySymbol() +
            pointsToUnits(offer.discountPrice);
        }

        return normalizeProperties(offer as CoreOffer);
      });

      return result;
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const response = await offerCrud.getOne<{ data: CoreOffer }>(params);
      const data = response?.data;

      if (data) {
        data.barcodes = (data.barcodes || []).join('\n') as unknown as string[];
        data.minBasketDiscountQuantity =
          !G.isNull(data.minBasketDiscountQuantity) &&
          !isNaN(Number(data.minBasketDiscountQuantity)) &&
          Number(data.minBasketDiscountQuantity) > 0
            ? `${data.minBasketDiscountQuantity}`
            : '';

        if (!G.isNull(data.minBasketDiscountPrice)) {
          data.minBasketDiscountPrice = pointsToUnits(
            data.minBasketDiscountPrice
          );
        }

        if (!G.isNull(data.price)) {
          data.price = pointsToUnits(data.price);
        }

        if (!G.isNull(data.discountPrice)) {
          data.discountPrice = pointsToUnits(data.discountPrice);
        }

        if (!G.isNull(data.cost)) {
          data.cost = pointsToUnits(data.cost);
        }

        if (
          data.isBackorderAvailable &&
          typeof data.backorderLeadTime === 'number'
        ) {
          data.backorderLeadTime =
            data.backorderLeadTime.toString() as unknown as number;
        } else if (!data.isBackorderAvailable) {
          data.backorderLeadTime = null;
        }
      }

      return {
        data:
          data !== undefined
            ? normalizeProperties(data as CoreOffer)
            : undefined,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      params.data = {
        offers: params.data.map((offer: CoreOffer) => {
          offer.name = offer.name?.trim();
          offer.price = unitsToPoints(offer.price as string);

          offer.minBasketDiscountPrice = offer.minBasketDiscountPrice
            ? unitsToPoints(offer.minBasketDiscountPrice)
            : null;

          offer.minBasketDiscountQuantity =
            typeof offer.minBasketDiscountQuantity === 'string'
              ? Number(offer.minBasketDiscountQuantity || '0')
              : offer.minBasketDiscountQuantity;

          if (offer.discountPrice) {
            offer.discountPrice = unitsToPoints(offer.discountPrice as string);
          } else {
            offer.discountPrice = null;
          }

          if (accessPermission.company?.isBackorderEnabled) {
            if (offer.backorderLeadTime) {
              offer.backorderLeadTime =
                typeof (offer.backorderLeadTime as unknown) === 'string' &&
                offer.backorderLeadTime
                  ? Number(offer.backorderLeadTime)
                  : offer.backorderLeadTime;
            }
          } else {
            offer.isBackorderAvailable = false;
            offer.backorderLeadTime = null;
          }

          return offer;
        }),
      };
      const result = await offerCrud.create<{ data: { offers: CoreOffer[] } }>({
        ...params,
        data: sanitizePayload(params.data),
      });

      return {
        data: result?.data?.offers[0],
      };
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await offerCrud.delete(params);

      return {
        data: params.previousData,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      params.data.properties = await uploadImages(params.data.properties);
      params.data.properties = params.data.properties
        .sort(sortByOrderIndex)
        .map(submitPropertyValueByType);
      params.data.name = params.data.name?.trim();

      const previousData = Object.assign(
        params.previousData || {},
        params.data || {}
      );

      params.data.vatAmount = parseFloat(params.data.vatAmount);
      params.data.barcodes = params.data.barcodes.split('\n');

      const formatingFields: Record<string, number | null> = {
        minBasketDiscountPrice: params.data.minBasketDiscountPrice
          ? unitsToPoints(params.data.minBasketDiscountPrice)
          : null,
        minBasketDiscountQuantity:
          typeof params.data.minBasketDiscountQuantity === 'string'
            ? Number(params.data.minBasketDiscountQuantity || '0')
            : params.data.minBasketDiscountQuantity,
        price: unitsToPoints(params.data.price),
        backorderLeadTime:
          typeof params.data.backorderLeadTime === 'string' &&
          params.data.backorderLeadTime
            ? Number(params.data.backorderLeadTime)
            : params.data.backorderLeadTime,
        cost: unitsToPoints(params.data.cost),
      };

      if (params.data.discountPrice) {
        formatingFields.discountPrice = unitsToPoints(
          params.data.discountPrice
        );
      } else {
        formatingFields.discountPrice = null;
      }

      if (
        !accessPermission.company?.isBackorderEnabled ||
        !params.data.isBackorderAvailable
      ) {
        params.data.isBackorderAvailable = false;
        params.data.backorderLeadTime = null;
      }

      try {
        await offerCrud.update({
          ...params,
          data: sanitizePayload({
            ...params.data,
            ...formatingFields,
          }),
        });

        return {
          data: previousData,
        };
      } catch (error) {
        if (error instanceof BackendCodeError) {
          const translatedText = pluginManager.i18nProvider.translate(
            error.translationKey
          );

          throw new HttpError(translatedText, error.status, {
            errors: {
              [error.field]: translatedText,
            },
          });
        }

        if (error instanceof ValidationError) {
          const translatedText = pluginManager.i18nProvider.translate(
            'catalogue.pages.offers.errors.validationError'
          );

          const errors: Record<string, string> = {};

          for (const [field, errorMessage] of Object.entries(error.fields)) {
            if (field.startsWith('_')) {
              continue;
            }

            errors[field] = errorMessage.join(', ');
          }

          throw new HttpError(translatedText, 400, {
            errors: errors,
          });
        }

        throw error;
      }
    },
    updateMany: async (resource, params): Promise<UpdateManyResult> => {
      if (params.meta === UPDATE_LIST_BY_FILE_UPLOAD) {
        await offerCrud.uploadFile(params);

        return {
          data: [],
        };
      }

      return {
        data: [],
      };
    },
    getManyReference: async (
      resource,
      params
    ): Promise<GetManyReferenceResult> => {
      const { target } = params;

      return target === ResourceMeta.inStockInformation
        ? inStockInformationCrud.list({
            ...params,
          })
        : Promise.reject(new Error());
    },
  },
});

export default OffersController;
