import { NestedListColumn } from '@Widgets/ResourceNestedList/interface';

import { CategoryName } from './components/columns/CategoryName';

export const REQUIRED_WITH_IMAGE_DISPLAY_TYPE = {
  PROMO_BANNER: 'promo_banner',
};

export const REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE = {
  MAIN_BANNER: 'main_banner',
  BANNER_CATEGORY: 'banner_category',
  BANNER_LINK: 'banner_link',
  CATEGORIES_CAROUSEL_SLIDER_1: 'categories_carousel_slider_1',
  CATEGORIES_CAROUSEL_SLIDER_2: 'categories_carousel_slider_2',
  CATEGORIES_CAROUSEL_SLIDER_3: 'categories_carousel_slider_3',
  CATEGORIES_GRID_1: 'categories_grid_1',
  CATEGORIES_GRID_2: 'categories_grid_2',
  LIST_OF_CATEGORIES_1: 'list_of_categories_1',
  LIST_OF_CATEGORIES_2: 'list_of_categories_2',
};

export const LANDING_PAGE: SelectOption[] = [
  {
    id: 'home',
    name: 'catalogue.pages.categories.filters.landingPages.home',
  },
  {
    id: 'shop',
    name: 'catalogue.pages.categories.filters.landingPages.shop',
  },
];

export const DISPLAY_TYPE: SelectOption[] = [
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.MAIN_BANNER,
    name: 'catalogue.pages.categories.filters.displayTypes.main_banner',
  },
  {
    id: REQUIRED_WITH_IMAGE_DISPLAY_TYPE.PROMO_BANNER,
    name: 'catalogue.pages.categories.filters.displayTypes.promo_banner',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.BANNER_CATEGORY,
    name: 'catalogue.pages.categories.filters.displayTypes.banner_category',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.BANNER_LINK,
    name: 'catalogue.pages.categories.filters.displayTypes.banner_link',
  },
  {
    id: 'banner_product',
    name: 'catalogue.pages.categories.filters.displayTypes.banner_product',
  },
  {
    id: 'products_carousel_slider_1',
    name: 'catalogue.pages.categories.filters.displayTypes.products_carousel_slider_1',
  },
  {
    id: 'products_carousel_slider_2',
    name: 'catalogue.pages.categories.filters.displayTypes.products_carousel_slider_2',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.CATEGORIES_CAROUSEL_SLIDER_1,
    name: 'catalogue.pages.categories.filters.displayTypes.categories_carousel_slider_1',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.CATEGORIES_CAROUSEL_SLIDER_2,
    name: 'catalogue.pages.categories.filters.displayTypes.categories_carousel_slider_2',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.CATEGORIES_CAROUSEL_SLIDER_3,
    name: 'catalogue.pages.categories.filters.displayTypes.categories_carousel_slider_3',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.CATEGORIES_GRID_1,
    name: 'catalogue.pages.categories.filters.displayTypes.categories_grid_1',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.CATEGORIES_GRID_2,
    name: 'catalogue.pages.categories.filters.displayTypes.categories_grid_2',
  },
  {
    id: 'products_grid',
    name: 'catalogue.pages.categories.filters.displayTypes.products_grid',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.LIST_OF_CATEGORIES_1,
    name: 'catalogue.pages.categories.filters.displayTypes.list_of_categories_1',
  },
  {
    id: REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE.LIST_OF_CATEGORIES_2,
    name: 'catalogue.pages.categories.filters.displayTypes.list_of_categories_2',
  },
  // TODO: Remove when LOCALEE will move to separate env
  {
    id: 'categories_grid_3_level',
    name: 'catalogue.pages.categories.filters.displayTypes.categories_grid_3_level',
  },
  {
    id: 'categories_carousel_square',
    name: 'catalogue.pages.categories.filters.displayTypes.categories_carousel_square',
  }
];

export const TILE_SIZE: SelectOption[] = [
  {
    id: 1,
    name: '1',
  },
  {
    id: 2,
    name: '2',
  },
  {
    id: 3,
    name: '3',
  },
];

export const LIST_COLUMNS: NestedListColumn[] = [
  {
    source: 'name',
    type: 'function',
    label: 'catalogue.pages.categories.labels.name',
    function: (record) => {
      return <CategoryName record={record} />;
    },
    shifted: true,
  },
  {
    source: 'id',
    type: 'text',
    label: 'catalogue.pages.categories.labels.id',
  },
  {
    source: 'active',
    type: 'boolean',
    label: 'catalogue.pages.categories.labels.active',
  },
  {
    source: 'sorting',
    type: 'text',
    label: 'catalogue.pages.categories.labels.sorting',
  },
  {
    source: 'tileSize',
    type: 'text',
    label: 'catalogue.pages.categories.labels.tileSize',
  },
  {
    source: 'createdAt',
    type: 'date',
    label: 'catalogue.pages.categories.labels.createdAt',
  },
  {
    source: 'updatedAt',
    type: 'date',
    label: 'catalogue.pages.categories.labels.updatedAt',
  },
];

export const DEEP_LINK_PREFIX = 'category_';

export const DEFAULT_PAGE_FORM_VALUES: Omit<
  CoreCategory,
  | 'status'
  | 'properties'
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'warehouseBlacklist'
> = {
  advertisingPlaceImage: '',
  code: '',
  externalId: null,
  active: false,
  deepLink: DEEP_LINK_PREFIX,
  description: '',
  descriptionAr: '',
  images: [],
  name: '',
  nameAr: '',
  sorting: 0,
  parentId: null,
  previewImage: { url: '' },
  previewImageThumb: { url: '' },
  previewText: '',
  backgroundColor: null,
  fontColor: null,
  tileSize: 1,
  slug: '',
  filters: [],
};
